import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Coders Beyond Borders</title>
        <meta property="og:title" content="Character NFT template" />
      </Helmet>
      <header data-thq="thq-navbar" className="home-navbar">
      <span className="home-logo"><img
          alt="image"
          src="/logo.png"
          className=""
        /></span>
        <div
          data-thq="thq-navbar-nav"
          data-role="Nav"
          className="home-desktop-menu"
        >
          <nav
            data-thq="thq-navbar-nav-links"
            data-role="Nav"
            className="home-nav"
          >
            <a href='#about'><button className="home-button button-clean button">About</button></a>
            <a href='#services'><button className="home-button1 button-clean button">
            Services
            </button></a>
            <a href='#projects'><button className="home-button2 button-clean button">
            Projects
            </button></a>
            <a href='#impact'><button className="home-button3 button-clean button">Impact</button></a>
            <a href='#faq'><button className="home-button4 button-clean button">FAQ</button></a>
            
          </nav>
        </div>
        <div data-thq="thq-navbar-btn-group" className="home-btn-group">
          <div className="home-socials">
            <button className="social button">
              <img
                alt="image"
                src="/Icons/twitter.svg"
                className="home-image"
              />
            </button>
            <button className="social button">
              <img
                alt="image"
                src="/Icons/discord.svg"
                className="home-image01"
              />
            </button>
          </div>
          <a href='#contact'><button className="button">Contact Us</button></a>
        </div>
        <div data-thq="thq-burger-menu" className="home-burger-menu">
          <button className="button home-button5">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </button>
        </div>
        <div data-thq="thq-mobile-menu" className="home-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="home-nav1"
          >
            <div className="home-container1">
              <span className="home-logo1">&lt;cbb&#47;&gt;</span>
              <div data-thq="thq-close-menu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="home-nav2"
            >
              <a href='#about'><span className="home-text">About</span></a>
              <a href='#services'><span className="home-text01">Services</span></a>
              <a href='#projects'><span className="home-text02">Projects</span></a>
              <a href='#impact'><span className="home-text03">Impact</span></a>
              <a href='#contact'><span className="home-text04">Contact</span></a>
            </nav>
            <div className="home-container2">
              <button className="home-login button">Login</button>
              <button className="button">Register</button>
            </div>
          </div>
          <div className="home-icon-group">
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <section className="home-hero">
        <div className="home-heading">
      
          <h1 className="home-header">Coders Beyond Borders</h1>
          <p className="home-caption">
          At Coders Beyond Borders, we are more than just a coding company; we are a global movement dedicated to leveraging the power of code for positive change.</p>        
          </div>
        <div className="home-buttons">
           <a href='#contact'><button className="button">Request a Meeting</button></a>
           <a href='#about'><button className="home-learn button-clean button">Learn more</button></a>
        </div>
        
      </section>
      <section className="home-description" id='about'>
        
        <div className="home-container3">
          <div className="home-description01">
            
            <div className="home-links">
             
              <a
                href=""
                
                rel="noreferrer noopener"
                className="home-link01 button-link button"
              >
               <span><strong>Our Vision</strong><br/><br/>We believe in the transformative power of technology to break barriers and create opportunities for all. Our mission is to empower individuals, particularly those with disabilities, within the tech sector. Through advanced Information and Communication Technology (ICT) training methods, we strive to narrow skill disparities, promote inclusivity, and establish pathways for underserved demographics.

                </span>
                
              </a>
            </div>

            <div className="home-links">
             
              <a
                href=""
                
                rel="noreferrer noopener"
                className="home-link01 button-link button"
              >
               <span><strong>Reasoning</strong><br/><br/>A 2021 Stack Overflow survey revealed that over 92 percent of developers were male, 
                with less than 2 percent having a physical difference. 
                Despite these disparities, significant untapped potential 
                exists to empower women and people with disabilities 
                to acquire these critical ICT skills. 
                Furthermore, the growing trend of remote work in the ICT sector 
                opens substantial opportunities, especially for individuals 
                with physical disabilities, while accommodating those 
                with visual or hearing impairments. 
                </span>
                
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="home-cards" id='services'>
        
        <p className="home-paragraph">
        <h3>Services</h3>
              <span>Explore the diverse services and projects that define our commitment 
              to making technology accessible to everyone, 
              regardless of geographical boundaries.</span>
              </p>

              <div className="home-row">

          
            <div className="home-card">
              <div className="home-avatar">
                <img
                  alt="image"
                  src="/Avatars/avatar.svg"
                  className="home-avatar1"
                />
              </div>
              <div className="home-main">
                <div className="home-content01">
                  <h2 className="home-header01">Web Development and Design</h2>
                  <p className="home-description02">
                  Transform your digital presence with our cutting-edge web development and design services. Our skilled team of developers and designers work collaboratively to create websites that not only meet industry standards but also reflect your unique identity. Whether you're starting from scratch or looking to revamp your existing site, we specialize in crafting user-friendly, visually appealing, and responsive websites. Embrace the power of the online world with our customized web solutions.
                  </p>
                </div>
                
                <h3>Services include:</h3>
                <p className="home-learn1">
               
                  <ul>
                  <li className="">Website Development</li>
                  <li className="">Responsive Design</li>
                  <li className="">E-commerce Solutions</li>
                  <li className="">Content Management Systems (CMS)</li>
                  <li className="">Website Maintenance and Support</li>
                  </ul>
                  
                </p>
              </div>
            </div>
            <div className="home-card01">
              <div className="home-avatar2">
                <img
                  alt="image"
                  src="/Avatars/default-avatar.svg"
                  className="home-avatar3"
                />
              </div>
              <div className="home-main1">
                <div className="home-content02">
                  <h2 className="home-header02">
                  Mobile App Development
                  </h2>
                  <p className="home-description03">
                  Stay connected with your audience on the go through our mobile app development services. We specialize in creating innovative and user-friendly mobile applications for iOS and Android platforms. From concept to deployment, our experienced team ensures that your app aligns with your business goals and user expectations. Whether you're launching a new product or enhancing customer engagement, our mobile solutions are designed to make a lasting impact.
                  </p>
                </div>
                <h3>Services include:</h3>
                <p className="home-learn1">
               
                  <ul>
                  <li className="">iOS App Development</li>
                  <li className="">Android App Development</li>
                  <li className="">Cross-Platform App Development</li>
                  <li className="">App Prototyping and Design</li>
                  <li className="">App Maintenance and Updates</li>
                  </ul>
                  
                </p>
              </div>
            </div>
            </div>

            <div className="home-card02">
          <div className="home-avatar4">
            <img
              alt="image"
              src="/Avatars/light-avatar.svg"
              className="home-avatar5"
            />
          </div>
          <div className="home-row1">
            <div className="home-main2">
              <div className="home-content03">
                <h2 className="home-header03">
                Design and Digital Marketing
                </h2>
                <p className="home-description04">
                Elevate your brand with our comprehensive design and digital marketing services. Our creative team brings ideas to life through captivating graphic design that communicates your brand story. Additionally, our social media marketing experts can help you establish a strong online presence and engage with your target audience effectively. From eye-catching visuals to strategic digital campaigns, we offer a holistic approach to boost your brand visibility and drive results.
                </p>
              </div>
              <h3>Services include:</h3>
                <p className="home-learn1">
               
                  <ul>
                  <li className="">Graphic Design</li>
                  <li className="">Branding and Logo Design</li>
                  <li className="">Social Media Marketing</li>
                  <li className="">Content Creation</li>
                  <li className="">Digital Marketing Campaigns</li>
                  </ul>
                  
                </p>
            </div>
            <img alt="image" src="/group%202262.svg" className="home-image05" />
          </div>
        </div>


            


        <div className="home-row">

          
          <div className="home-card">
            <div className="home-avatar">
              <img
                alt="image"
                src="/Avatars/avatar.svg"
                className="home-avatar1"
              />
            </div>
            <div className="home-main">
              <div className="home-content01">
                <h2 className="home-header01">Skill Development Initiatives</h2>
                <p className="home-description02">
                We conduct coding workshops, webinars, and 
                mentorship programs to enhance coding skills 
                and prepare individuals for the tech industry.
                </p>
              </div>
             
            </div>
          </div>
          <div className="home-card01">
            <div className="home-avatar2">
              <img
                alt="image"
                src="/Avatars/default-avatar.svg"
                className="home-avatar3"
              />
            </div>
            <div className="home-main1">
              <div className="home-content02">
                <h2 className="home-header02">
                  Global Collaboration
                </h2>
                <p className="home-description03">
                Through our global network, we connect coders worldwide, 
                fostering collaboration, knowledge-sharing, and diverse perspectives.
                </p>
              </div>
              
            </div>
          </div>
        </div>
        <div className="home-card02">
          <div className="home-avatar4">
            <img
              alt="image"
              src="/Avatars/light-avatar.svg"
              className="home-avatar5"
            />
          </div>
          <div className="home-row1">
            <div className="home-main2">
              <div className="home-content03">
                <h2 className="home-header03">
                  Mentorship Programs
                </h2>
                <p className="home-description04">
                Connect with industry professionals and experienced mentors 
                who will guide you through your coding journey. 
                Our mentorship programs are tailored to provide 
                personalized support and insights.
                </p>
              </div>
              
            </div>
            <img alt="image" src="/group%202262.svg" className="home-image05" />
          </div>
        </div>
      </section>
      <section className="home-collection" id='projects'>
        <div className="home-content04">
          <span className="home-caption01">PROJECTS</span>
          <div className="home-heading01">
            <h2 className="home-header04">All time best collections</h2>
            <p className="home-header05">
              We have developed websites and other tech solutions across diverse domains including travel, hospitality, nonprofits, news, and education.
            </p>
          </div>
        </div>
        <div className="home-main3">
          <div className="home-card03">
            <div className="home-image06">
            <a href="https://chinarkashmir.org" target="_new" title='CHINAR Kashmir'><img
                alt="image"
                src="/Characters/character-1.svg"
                className="home-image07"
              /></a>
            </div>
            <div className="home-content05">
              <a href="https://chinarkashmir.org" target="_new" title="CHINAR Kashmir">
                <h3 className="home-title">CHINAR Kashmir</h3>
              </a>
              <span className="">CHINAR Kashmir is a non-political and non-profit organisation focusing on improving the lives of marginalised children, women and families based in Jammu and Kashmir. CHINAR Kashmir runs several innovative programs geared towards education, empowerment, relief work and health care.</span>
              
            </div>
          </div>
          <div className="home-card04">
            <div className="home-image08">
            <a href="https://www.eyes4u.in" target="_new" title="Eyes4U"><img
                alt="image"
                src="/Characters/character-2.svg"
                className="home-image09"
              /></a>
            </div>
            <div className="home-content06">
            <a href="https://www.eyes4u.in" target="_new" title="Eyes4U">
                <h3 className="home-title">Eyes4U</h3>
              </a>
              <span className="">Eyes4U is a web-application that assists visually challenged individuals find scribes that write on their behalf. Blind or partially blind students are finding it difficult to get writers/scribes to appear on their behalf for writing examinations. On the other hand, there are many good people who are ready to volunteer as a writer/scribe for the visually challenged candidates. Eyes For You helps to connect the two.</span>
              
            </div>
          </div>
          <div className="home-card05">
            <div className="home-image10">
            <a href="https://charityclicks.net" target="_new" title="Charity Clicks"><img
                alt="image"
                src="/Characters/character-3.svg"
                className="home-image11"
              /></a>
            </div>
            <div className="home-content07">
            <a href="https://charityclicks.net" target="_new" title="Charity Clicks">
                <h3 className="home-title">Charity Clicks</h3>
              </a>
              <span className="">Charity Clicks offers a helping hand to nonprofits by crafting custom websites, completely free. They offer multiple services to empower NGOs to amplify their impact through a compelling online presence.
</span>
              
            </div>
          </div>
          <div className="home-card03">
            <div className="home-image12">
            <a href="https://javafullstack.info" target="_new" title="Java Full Stack"><img
                alt="image"
                src="/Characters/character-4.svg"
                className="home-image11"
              /></a>
            </div>
            <div className="home-content07">
            <a href="https://javafullstack.info" target="_new" title="Java Full Stack">
                <h3 className="home-title">Java Full Stack</h3>
              </a>
              <span className="">Java Full Stack is a place to learn Full Stack Development using Java. Here we will walk through a step-by-step guided roadmap to become a Java Full Stack Developer. As you will follow along the roadmap, you will have a good understanding of what it means to be a Java Full Stack Developer and how to become one.</span>
        
            </div>
          </div>
          <div className="home-card07">
            <div className="home-image14">
            <a href="https://theinduspost.com" target="_new" title="The Indus Post"><img
                alt="image"
                src="/Characters/character-5.svg"
                className="home-image15"
              /></a>
            </div>
            <div className="home-content09">
            <a href="https://theinduspost.com" target="_new" title="The Indus Post">
                <h3 className="home-title">The Indus Post</h3>
              </a>
              <span className="">The Indus Post is a news magazine to tell the story, as it is. The Indus Post acknowledges good writing and original thought to give readers a distinctive views. They started as monthly magazine carrying longform pieces, ground reportage and interviews. After faring online, they have made valuable additions in the form of daily updates, multimedia packages, essays, analyses, book reviews, and short stories.</span>
              
            </div>
          </div>
          <div className="home-card08">
            <div className="home-image16">
            <a href="https://www.travelihood.com" target="_new" title="Travelihood"> <img
                alt="image"
                src="/Characters/character-6.svg"
                className="home-image17"
              /></a>
            </div>
            <div className="home-content10">
            <a href="https://www.travelihood.com" target="_new" title="Travelihood">
                <h3 className="home-title">Travelihood</h3>
              </a>
              <span className="">Based in Kashmir, Travelihood is a travel company providing unconventional travel experience in volunteer, leisure, adventure and educational tourism.</span>
              
            </div>
          </div>
          <div className="home-card09">
            <div className="home-image10">
            <a href="https://www.slovocentre.co.za/" target="_new" title="Slovo Centre"> <img
                alt="image"
                src="/Characters/character-7.svg"
                className="home-image19"
              /></a>
            </div>
            <div className="home-content11">
            <a href="https://www.slovocentre.co.za/" target="_new" title="Slovo Centre">
                <h3 className="home-title">Slovo Centre</h3>
              </a>
              <span className="">Slovo Centre of Excellence is a South African NPO that provides various educational support programmes with a key focus on play based learning. They also provide a safe environment where children can thrive through play and innovative programmes like Edu-Fun, BrainBoosters and Computer literacy for children. Their training and Development programmes provide job exposure to working with children through play based methodology.</span>
              
            </div>
          </div>
          <div className="home-card10">
            <div className="home-image20">
            <a href="https://gulmargcountryside.com" target="_new" title="Gulmarg Countryside"><img
                alt="image"
                src="/Characters/character-8.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://gulmargcountryside.com" target="_new" title="Gulmarg Countryside">
                <h3 className="home-title">Gulmarg Countryside</h3>
              </a>
              <span className="">Gulmarg Countryside is a hotel based in Gulmarg Kashmir. They offer luxurious accommodations, world-class amenities, and personalized service to ensure a memorable stay.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image20">
            <a href="https://www.last2lines.com" target="_new" title="Last2Lines"> <img
                alt="image"
                src="/Characters/character-9.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://www.last2lines.com" target="_new" title="Last2Lines">
                <h3 className="home-title">Last2Lines</h3>
              </a>
              <span className="">Last2Lines is a groundbreaking AI-powered platform that revolutionizes poetry campaigning by inviting users to express and record their peaceful sentiments through two lines of poetry.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image14">
            <a href="https://www.aym-inc.org" target="_new" title="Africa Youth Movement"><img
                alt="image"
                src="/Characters/character-10.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://www.aym-inc.org" target="_new" title="Africa Youth Movement">
                <h3 className="home-title">Africa Youth Movement</h3>
              </a>
              <span className="">AYM is a youth-serving organization that encourages and works with young people to explore creative solutions to poverty. AYM engages youth as change-makers in rebuilding and developing rural communities through innovative and progressive local-driven programs and enterprises.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image15">
            <a href="https://goldenwheels.co.in" target="_new" title="Golden Wheels"><img
                alt="image"
                src="/Characters/character-11.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://goldenwheels.co.in" target="_new" title="Golden Wheels">
                <h3 className="home-title">Golden Wheels</h3>
              </a>
              <span className="">Golden Wheels is a transpoter company based in Kashmir India. With a dedicated team and a range of services including rentals and professional drivers, they deliver exceptional experiences tailored to the needs of their customers.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image16">
            <a href="https://www.grtravel.co.in" target="_new" title="G R Travel"> <img
                alt="image"
                src="/Characters/character-12.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://www.grtravel.co.in" target="_new" title="G R Travel">
                <h3 className="home-title">G R Travel</h3>
              </a>
              <span className="">G R Travel is a tour company based in New Delhi and Kashmir India. They offer multiple tour services like hotels, flights, cabs, guides and more.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image18">
            <a href="https://auspiciouskashmir.co.in" target="_new" title="Auspicious Kashmir"><img
                alt="image"
                src="/Characters/character-13.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://auspiciouskashmir.co.in" target="_new" title="Auspicious Kashmir">
                <h3 className="home-title">Auspicious Kashmir</h3>
              </a>
              <span className="">Auspicious Kashmir is a tour company based in Kashmir India. With over 15 years of experience, they offer all kinds of vacations in Kashmir, Ladakh and Himachal. They offer great services at affordable prices.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image20">
            <a href="https://goldentrot.com" target="_new" title="Golden trot"><img
                alt="image"
                src="/Characters/character-14.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://goldentrot.com" target="_new" title="Golden trot">
                <h3 className="home-title">Golden Trot</h3>
              </a>
              <span className="">Golden Trot is an Equestrian Centre and Riding School based in Pretoria, South Africa. They are a highly collaborative and supportive team, sharing the same passion and love for horses.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image14">
            <a href="https://buroojenterprise.co.za" target="_new" title="Burooj"><img
                alt="image"
                src="/Characters/character-15.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://buroojenterprise.co.za" target="_new" title="Burooj">
                <h3 className="home-title">Burooj Enterprise</h3>
              </a>
              <span className="">Burooj Enterprise is a consultancy firm based in South Africa. They specialise in management and engineering to help clients properly brainstorm the Acquisition Management elements to develop effective acquisition programs and procedures.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image18">
            <a href="https://skinlity.com" target="_new" title="Skinlity">
              <img
                alt="image"
                src="/Characters/character-16.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://skinlity.com" target="_new" title="Skinlity">
                <h3 className="home-title">Skinlity</h3>
              </a>
              <span className="">Skinlity is a premium skin, hair, and body clinic based in New delhi India where beauty meets science for healthy and radiant looks. They help clients achieve their best self with their expertly curated selection of effective and gentle products.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image20">
              <a href="https://capitalstudyabroad.com/" target="_new" title="Capital Study Abroad">
              <img
                alt="image"
                src="/Characters/character-17.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://capitalstudyabroad.com/" target="_new" title="Capital Study Abroad">
                <h3 className="home-title">Capital Study Abroad</h3>
              </a>
              <span className="">Capital study abroad helps students in admissions and visa processing services for countries like the USA, UK, Canada, Australia, New Zealand, Germany, France, Singapore, Malaysia, and other European countries.</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image18">
              <a href="https://aquatechwin.com" target="_new" title="Aquatechwin"><img
                alt="image"
                src="/Characters/character-18.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
              <a href="https://aquatechwin.com" target="_new" title="Aquatechwin">
                <h3 className="home-title">Aquatechwin</h3>
              </a>
              <span className="">AquaTechwin provides diversified water purification systems and services for your pure water treatment requirement. AquaTechwin designs, fabricates and installs the water purification systems and provides regular maintenance services. We have successfully provided our best quality water systems and services to a large number of homes. Besides, we also serve organizations and corporate houses, schools, colleges, universities, hospitals, hotels & restaurants, poultry and dairy farms among others.​</span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image14">
              <a href="https://jinathaimassage.co.za" target="_new" title="Jina Thai Massage"><img
                alt="image"
                src="/Characters/character-19.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="https://jinathaimassage.co.za" target="_new" title="Jina Thai Massage">
                <h3 className="home-title">Jina Thai Massage</h3>
              </a>
              <span className="">Jina Thai massage is a massage center based in Johannesburg South Africa. They offer Thai massage treatments, therapeutic, aromatherapy, pressure points, bending, pulling sore muscles, and sports massages. </span>
              
            </div>
            
          </div>

          <div className="home-card10">
            <div className="home-image12">
              <a href="http://jkpie.net" target="_new" title="JK PIE"><img
                alt="image"
                src="/Characters/character-20.svg"
                className="home-image21"
              /></a>
            </div>
            <div className="home-content12">
            <a href="http://jkpie.net" target="_new" title="JK PIE">
                <h3 className="home-title">JK PIE</h3>
              </a>
              <span className="">JK PIE is an alliance of NGOs and civil society members who are a driving force for change in the education sector of J&K (Jammu & Kashmir). Their mission is to empower the youth of J&K by providing them with the tools, resources, and support they need to access quality education.</span>
              
            </div>
            
          </div>
        </div>
        <button className="home-view2 button-link button">
              <span><a href="#contact">Get In Touch</a></span>
              <img
                alt="image"
                src="/Icons/arrow.svg"
                className="home-image22"
              />
            </button>
      </section>
      <section className="home-project" id='joinus'>
        <div className="home-understand">
          <div className="home-content13">
            <span className="home-caption10">Join Us</span>
            <div className="home-heading02">
              <h2 className="home-header06">You are welcome</h2>
              <p className="home-header07">
              Whether you're a coding enthusiast, a seasoned developer, 
              or someone curious about the world of technology, 
              Coders Beyond Borders welcomes you. Join our community 
              and be part of a movement that believes in the
              potential of code to change lives.
              </p>
            </div>
            <button className="home-view3 button-link button">
              <span><a href="#contact">Get In Touch</a></span>
              <img
                alt="image"
                src="/Icons/arrow.svg"
                className="home-image22"
              />
            </button>
          </div>
          <img alt="image" src="/group%202415.svg" className="home-image23" />
        </div>
        <div className="home-mining" id='impact'>
          <img alt="image" src="/group%202422.svg" className="home-image24" />
          <div className="home-content14">
            <span className="home-caption11">Impact</span>
            <div className="home-heading03">
              <h2 className="home-header08">Our Impact Stories</h2>
              <p className="home-header09">
                <span>
                Explore real stories of individuals whose lives have been transformed 
                through Coders Beyond Borders. 
                </span>
                <br></br>
                <br></br>
                <span>
                From acquiring essential coding skills 
                to working on impactful projects, our community members make a difference.                </span>
                <br></br>
              </p>
            </div>
            <button className="home-view4 button-link button">
              <a href="#contact"><span>Learn More</span></a>
              <img
                alt="image"
                src="/Icons/arrow.svg"
                className="home-image25"
              />
            </button>
          </div>
        </div>
      </section>
      <section className="home-roadmap" id="roadmap">
        <div className="home-heading04">
          <h2 className="home-header10">What Makes Us Unique</h2>
          <p className="home-header11">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore
          </p>
        </div>
        <div className="home-list">
          <div className="home-step">
            <span className="home-caption12">01</span>
            <div className="home-heading05">
              <h2 className="home-header12">Innovative Training Methods</h2>
              <p className="home-header13">
              Experience cutting-edge ICT training designed to not only meet industry standards but anticipate future trends, ensuring you stay ahead in the rapidly evolving tech landscape.
              </p>
            </div>
          </div>
          <div className="home-step1">
            <span className="home-caption13">02</span>
            <div className="home-heading06">
              <h2 className="home-header14">Inclusive Approach</h2>
              <p className="home-header15">
                <span>
                Our commitment to inclusivity goes beyond rhetoric. Our programs are carefully crafted to accommodate diverse learning styles and abilities, fostering an environment where everyone can thrive.


                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                
                <br></br>
              </p>
            </div>
          </div>
          <div className="home-step2">
            <span className="home-caption14">03</span>
            <div className="home-heading07">
              <h2 className="home-header16">Clear Pathways for Success</h2>
              <p className="home-header17">
                <span>
                Corders-Beyond-Borders isn't just about training; it's about creating tangible pathways for underserved demographics. Through mentorship, networking, and industry partnerships, we guide you towards a successful and fulfilling career in the tech sector.


                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                
                <br></br>
              </p>
            </div>
          </div>
          
        </div>
      </section>
      <section className="home-join-us">
        <div className="home-content15">
          <div className="home-main4">
            <div className="home-heading09">
              <h2 className="home-header23">Get Involved</h2>
              <img alt="image" src="/group%202273.svg" className="home-image2" />

             <p className="home-caption16">
              You can be a part of the change. Whether you're an individual looking to enhance your tech skills, a mentor eager to share your expertise, or a company interested in fostering a diverse and inclusive workplace, there's a place for you at Corders-Beyond-Borders.
              Connect with us on [social media icons]
              </p>
              <a href="#contact"><button className="home-view5 button">Get In Touch</button></a>
              <br/>
            </div>
          </div>
        </div>
      </section>
      <section className="home-faq" id="faq">
        <h2 className="home-header24">We have all the answers</h2>
        <div className="home-accordion">
          <div
            data-role="accordion-container"
            className="home-element accordion"
          >
            <div className="home-content16">
              <span className="home-header25">
              What is Corders-Beyond-Borders?
              </span>
              <span
                data-role="accordion-content"
                className="home-description05"
              >
               Corders-Beyond-Borders is an initiative dedicated to empowering individuals, especially those with disabilities, within the tech sector. Through advanced Information and Communication Technology (ICT) training methods, we aim to bridge skill disparities, promote inclusivity, and create pathways for underserved demographics.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon10"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon12"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element1 accordion"
          >
            <div className="home-content17">
              <span className="home-header26">
              Who can participate in Corders-Beyond-Borders programs?
              </span>
              <span
                data-role="accordion-content"
                className="home-description06"
              >
                Our programs are open to individuals of all backgrounds and abilities. We specifically design our training to be inclusive, catering to diverse learning styles and abilities. Whether you're a person with a disability seeking tech skills empowerment or someone interested in supporting our mission, there's a place for you at Corders-Beyond-Borders.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container1">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon14"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon16"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element2 accordion"
          >
            <div className="home-content18">
              <span className="home-header27">
              What kind of ICT training do you provide?
              </span>
              <span
                data-role="accordion-content"
                className="home-description07"
              >
               We offer cutting-edge training in various areas of Information and Communication Technology (ICT), including but not limited to coding, cybersecurity, and digital literacy. Our goal is to equip participants with skills that meet current industry standards and prepare them for the evolving tech landscape.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container2">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon18"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon20"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element3 accordion"
          >
            <div className="home-content19">
              <span className="home-header28">
              How is Corders-Beyond-Borders promoting inclusivity?
              </span>
              <span
                data-role="accordion-content"
                className="home-description08"
              >
                Inclusivity is at the core of our mission. We actively work to remove barriers to participation, both physical and digital, ensuring that our programs are accessible to everyone. Our training modules are designed to accommodate diverse learning needs, and we strive to create an environment where every individual feels valued and included.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container3">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon22"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon24"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="accordion-container"
            className="home-element4 accordion"
          >
            <div className="home-content20">
              <span className="home-header29">
              What support do you provide for job placement and career advancement?
              </span>
              <span
                data-role="accordion-content"
                className="home-description09"
              >
                We believe in not only providing training but also creating pathways for career success. Through mentorship programs, networking opportunities, and industry partnerships, we facilitate internships and job placements. Our goal is to support participants in their transition into the workforce and foster long-term success in the tech industry.

                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container4">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon26"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon28"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>

          <div
            data-role="accordion-container"
            className="home-element5 accordion"
          >
            <div className="home-content20">
              <span className="home-header29">
              How can I get involved with Corders-Beyond-Borders?
              </span>
              <span
                data-role="accordion-content"
                className="home-description09"
              >
There are various ways to get involved! Whether you're an individual looking to enhance your tech skills, a mentor eager to share your expertise, or a company interested in fostering diversity and inclusion, you can find a place at Corders-Beyond-Borders. Explore our website for information on volunteering, mentorship programs, and partnership opportunities.


                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container5">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon26"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon28"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>

          <div
            data-role="accordion-container"
            className="home-element6 accordion"
          >
            <div className="home-content20">
              <span className="home-header29">
              How can I support Corders-Beyond-Borders financially?
              </span>
              <span
                data-role="accordion-content"
                className="home-description09"
              >
Your support is crucial to our mission. You can contribute by making a donation through our website. Your financial support enables us to expand our programs, reach more individuals, and make a greater impact in the tech community.


                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container6">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon26"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon28"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>

          <div
            data-role="accordion-container"
            className="home-element4 accordion"
          >
            <div className="home-content20">
              <span className="home-header29">
              Is Corders-Beyond-Borders a global initiative?              </span>
              <span
                data-role="accordion-content"
                className="home-description09"
              >
Yes, our vision is global. While we may initially focus on specific regions, our long-term goal is to create a worldwide community where everyone, regardless of their background or abilities, has equal access to and opportunities within the technology sector.


                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container4">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon26"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon28"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>

          <div
            data-role="accordion-container"
            className="home-element4 accordion"
          >
            <div className="home-content20">
              <span className="home-header29">
              How can I stay updated on Corders-Beyond-Borders activities and events?
              </span>
              <span
                data-role="accordion-content"
                className="home-description09"
              >
To stay informed about our latest activities, events, and initiatives, subscribe to our newsletter on our website. You can also follow us on social media platforms for real-time updates and stories of impact.


                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container4">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon26"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon28"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>

          <div
            data-role="accordion-container"
            className="home-element4 accordion"
          >
            <div className="home-content20">
              <span className="home-header29">
              I have more questions. How can I contact Corders-Beyond-Borders?
              </span>
              <span
                data-role="accordion-content"
                className="home-description09"
              >
Feel free to reach out to us through our Contact Page on the website. We welcome your inquiries, feedback, and suggestions. Our team is here to assist you on your journey with Corders-Beyond-Borders.



                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-icon-container4">
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-closed"
                className="home-icon26"
              >
                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                data-role="accordion-icon-open"
                className="home-icon28"
              >
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>


        </div>
      </section>
      
      <section className="home-get-yours" id='contact'>
        <div className="home-row2">
          <div className="home-column">
            <div className="home-card11">
            <h2>Contact Us</h2>
            </div>
          </div>
          
        </div>
        <div className="home-column2">
          <div className="home-card13">
            <div className="home-content21">
              <h2 className="home-header30">Get In Touch</h2>
              <p className="">
              Have questions, suggestions, or eager to get involved? Reach out to us—we'd love to hear from you!
              </p> 
            
            </div>
            <a href="tel:916005507072"><button className="button">Call</button></a>

            <a href="https://wa.me/916005507072"><button className="button">WhatsApp</button></a>

            <a href="mailto:wajid.cordersbeyondborders@gmail.com"><button className="button">Send an Email</button></a>

            <a href="http://www.linkedin.com/in/abdulwajid786" target="_blank"><button className="button">Connect on LinkedIn</button></a>
          </div>
        </div>
      </section>
      <footer className="home-footer">
        <div className="home-main5">
          <div className="home-branding">
            <div className="home-heading10">
            <span className="home-logo"><img
                  alt="image"
                  src="/logo.png"
                  className=""
                /></span>
                <p className="home-copyright">
                Beyond Disability, Gender, Race, Color, Religion, and Caste.
                </p>
            </div>
            {/* <div className="home-socials1">
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-twitter1 social button"
              >
                <img
                  alt="image"
                  src="/Icons/twitter.svg"
                  className="home-image32"
                />
              </a>
              <a
                href="https://discord.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-discord1 social button"
              >
                <img
                  alt="image"
                  src="/Icons/discord.svg"
                  className="home-image33"
                />
              </a>
            </div> */}
          </div>
          <div className="home-links1">
            <div className="home-list1">
              <div className="home-items">
                <button className="home-link02 button-clean button">
                  About
                </button>
                <button className="home-link03 button-clean button">
                  Services
                </button>
                <button className="home-link04 button-clean button">
                  Projects
                </button>
                <button className="home-link04 button-clean button">
                  Get Involved
                </button>
                <button className="home-link05 button-clean button">
                  Contact
                </button>
              </div>
            </div>
            <div className="home-list2">
              <div className="home-items1">
                <button className="home-link06 button-clean button">
                  Why Us?
                </button>
                <button className="home-link07 button-clean button">
                  FAQ
                </button>
                <button className="home-link08 button-clean button">
                  Impact
                </button>
                <button className="home-link09 button-clean button">
                  TnC
                </button>
                <button className="home-link10 button-clean button">
                  Privacy
                </button>
              </div>
            </div>
          </div>
          {/* <div className="home-socials2">
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-twitter2 social button"
            >
              <img
                alt="image"
                src="/Icons/twitter.svg"
                className="home-image34"
              />
            </a>
            <a
              href="https://discord.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-discord2 social button"
            >
              <img
                alt="image"
                src="/Icons/discord.svg"
                className="home-image35"
              />
            </a>
          </div> */}
        </div>
        <span className="home-copyright">
          &copy; 2023 Coders Beyond Borders. All Rights Reserved.
        </span>
      </footer>
      <div>
        <div className="home-container5">
          <Script
            html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
